import request from "@/utils/request.js";

export const login = (params) => request.post("/api/users/login", params);
export const email_login = (params) => request.post("/api/users/email_login", params);
export const getUser = (params) => request.post("/api/users/get_user", params);
export const download = (params) => request.get("/api/users/get_user", params);
export const draw = (params) => request.post("/api/images/draw", params);
export const preserve_draw = (params) => request.post("/api/images/preserve_draw", params);
export const user_up = (params) => request.post("/api/images/user_up", params);
export const get_list = (params) => request.post("/api/index/get_list", params);
export const get_cat = (params) => request.post("/api/index/get_cat", params);
export const set_user = (params) => request.post("/api/users/set_user", params);
export const getOss = (params) => request.post("/api/lackvideo/get_asloss", params);
export const use_cami = (params) => request.post("/api/users/use_cami", params);

export const send_code = (params) => request.post("/api/users/send_code", params);
export const register = (params) => request.post("/api/users/register", params);
export const find_pass = (params) => request.post("/api/users/find_pass", params);
